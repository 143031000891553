import { ReactComponent as DesktopLettering } from "../assets/header-wip_desktop.svg";
import { ReactComponent as MobileLettering } from "../assets/header-wip_mobile.svg";
import useWindowSize from "../hooks/useWindowSize"

function WipPage() {
  const { width } = useWindowSize();

  const Lettering = width <= 720 ? MobileLettering : DesktopLettering;

  return (
    <div>
      <Lettering />
      <a href="https://www.instagram.com/veronica.aguilaar/">
        <p >
          Website under construction, check out my work <u>here</u> :)
        </p >
      </a>
    </div>
  )
}

export default WipPage;
