import "./HomePage.css";

import { Container, Row, Col } from 'react-bootstrap';

function HomePage() {
  return (
    <div className="body">
      <div className="text-banner">
        <div className="content-block">
          <h1> Welcome, my name is Verónica! 👋</h1>
        </div>
        <div className="content-block">
          <p>
            A cheerful and inspired Visual Designer that enjoys creating digital
            products with meaningful identities and memorable letter
            compositions, welcome to my site.
          </p>
        </div>
      </div>
      <div className="content-break" />
      <Container>
        <Row>
          <Col>
            <label> Visual Design </label>
            <h1>
              I collaborate with engineers and designers to create Digital
              Products 🖥️
            </h1>
            <button>Check out my work</button>
          </Col>
          <Col>
            <img
              width={"500px"}
              src="https://images.unsplash.com/photo-1454496522488-7a8e488e8606?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=2555&q=80"
            />
          </Col>
        </Row>
      </Container>
      <div className="content-break"/>
      <Container>
        <Row>
          <Col>
            <img
              width={"500px"}
              src="https://e.staedtlercdn.com/fileadmin/_processed_/7/1/csm_Handlettering_Spruchgestaltung3_504958d645.jpg"
            />
          </Col>
          <Col>
            <label> Visual Design </label>
            <h1>
              Outside Work I enjoy communicating through letters ✍️✨
            </h1>
            <button>Check out my work</button>
          </Col>
        </Row>
      </Container>
      <div className="content-break" />
    </div>
  );
}

export default HomePage;
